<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from "primevue/useconfirm"
import { FilterMatchMode } from 'primevue/api'
import axios from 'axios'
import Dropdown from 'primevue/dropdown'

/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()

// filters
const filter_pubs = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})

// data

const pubs = ref([])
const pub = ref(null)

const pub_type = ref('magazin')
const pub_category = ref('magazin')
const pub_content = ref()

const active_pub = ref(null)
const active_pub_type = ref(null)

const slider_details = ref(false)
const slider_builder = ref(false)

onMounted(() => {
    get_publications()
})

const pub_types = ref([
    { label: 'Magazin', value: 'magazin' },
    { label: 'Weingüter', value: 'producer'},
    { label: 'Events', value: 'events' },
    { label: 'Struktur', value: 'structure' },
    { label: 'Statisch', value: 'static' }
])

const pub_categories = ref({
    magazin: [
        { label: 'Magazin', value: 'magazin' },
        { label: 'News', value: 'news'},
        { label: 'Promotionen', value: 'promotionen' },
        { label: 'Subskriptionen', value: 'subskriptionen' },
        { label: 'Weinwissen', value: 'weinwissen'},
        { label: 'Wettbewerbe', value: 'wettbewerbe' },
        { label: 'Weinküche', value: 'kitchen'}
    ],
    producer: [
        { label: 'Standard', value: 'standard' }
    ],
    events: [
        { label: 'Degustationen', value: 'tasting' },
        { label: 'Weinauktionen', value: 'auction' },
        { label: 'Weinreisen', value: 'travel' },
        { label: 'Wine & Dines', value: 'dine' }
    ],
    structure: [
        { label: 'Hauptkategorien', value: 'main' },
        { label: 'Länder', value: 'country' },
        { label: 'Traubensorten', value: 'grape' }
    ],
    static: [
        { label: 'Standard', value: 'standard' }
    ]
})

const statuses = ref([
    { label: 'Entwurf', value: 'draft' },
    { label: 'Publiziert', value: 'published' }
])

const pub_details = (event) => {
    loader.value = true
    if (!pub.value) {pub.value = {}}
    if (event.data.workflow_id in pub.value) {
        active_pub.value = event.data.workflow_id
        active_pub_type.value = event.data.type
        slider_details.value = true
        loader.value = false
    } else {
        active_pub.value = event.data.workflow_id
        active_pub_type.value = event.data.type
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gerstlv2/get-publication', {"id": active_pub.value, "type": active_pub_type.value})
        .then(response => {
            pub.value[active_pub.value] = response.data
            console.log("pub", pub.value, response.data)
            slider_details.value = true
            loader.value = false
        })
    }
}

const re_edit = (workflow_id) => {
    console.log("re_edit", workflow_id)
    active_pub.value = workflow_id
    active_pub_type.value = pub.value[workflow_id].type
    slider_details.value = true
}

const content_builder = (content) => {
    console.log("content_builder")
    pub_content.value = content
    slider_builder.value = true
}

const migrate = () => {
    console.log("migrate")
}

const get_status = (status) => {
    return statuses.value.find(s => s.value == status).label
}

const get_type = (type) => {
    return pub_types.value.find(t => t.value == type).label
}

const get_category = (category, type) => {
    return pub_categories.value[type].find(c => c.value == category).label
}

const get_publications = () => {
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gerstlv2/get-publications')
    .then(response => {
        pubs.value = response.data
        console.log("pubs", pubs.value)
    })
}

const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

</script>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<style>
    .p-fieldset-legend {
        margin-left: 10px;
    }
    .p-card-content {
        padding: 0!important;
    }
</style>

<template>
    <Toast />
    <ConfirmDialog />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <Toolbar class="mt-2">
        <template #start>
            <Button v-for="ty in pub_types" :key="ty" rounded :label="ty.label" class="mr-2 bg-black-alpha-90 border-black-alpha-70" :badge="String(0)" badgeSeverity="contrast" @click="pub_type = ty.value" />
        </template>
    </Toolbar>
    <Toolbar class="mt-2">
        <template #start>
            <span v-for="cat in pub_categories[pub_type]" :key="cat" @click="pub_category = cat.value" class="mr-4 ml-3 hover:font-bold hover:cursor-pointer">{{ cat.label }}<Badge value="0" class="bg-gray-300 text-gray-600 ml-1" /></span>
        </template>
    </Toolbar>
    <Toolbar v-if="pub" class="mt-2">
        <template #start>
            <Chip v-for="(edit, key) in pub" :key="key" @click="re_edit(edit.workflow_id)" icon="pi pi-pencil" class="mr-2 hover:font-bold hover:cursor-pointer bg-red-700 text-white" :label="edit.title.substring(0,10) + '...'" />
        </template>
    </Toolbar>
    <Toolbar class="mt-2">
        <template #start>
            <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filter_pubs['global'].value" placeholder="Suche" style="width: 400px" class="mr-2" />
            </span>
            <span class="mr-4 ml-3 hover:font-bold hover:cursor-pointer">Fehlender Inhalt<Badge value="0" class="bg-red-700 text-white ml-1" /></span>
        </template>
        <template #end>
            <Button @click="migrate" v-tooltip.bottom="'Migrieren'" label="Migrieren" class="w-auto bg-purple-500 text-white border-purple-400 mx-2 mr-3" icon="pi pi-sync" />
        </template>
    </Toolbar>
    <div rounded class="overflow-hidden shadow-4 rounded border-200 mt-4">
        <DataTable v-model:filters="filter_pubs" :value="pubs" :rows="50" @row-click="pub_details" responsiveLayout="scroll" :rowsPerPageOptions="[50, 100, 300]" :rowHover="true" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
            <Column field="type" header="Typ">
                <template #body="slotProps">
                    <Chip :label="get_type(slotProps.data.type)" class="bg-primary text-white" />
                </template>
            </Column>
            <Column field="header_image" style="width: 130px">
                <template #body="slotProps">
                    <img :src="slotProps.data.header_image" width="32" class="shadow-2 mr-2" style="vertical-align: middle; width: 128px; height: 64px; object-fit: cover; border-radius: 5%; box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)" />
                </template>
            </Column>
            <Column field="title" header="Titel">
                <template #body="slotProps">
                    <span class="font-bold">{{ slotProps.data.title }}</span>
                </template>
            </Column>
            <Column field="category" header="Kategorie">
                <template #body="slotProps">
                    <Chip :label="get_category(slotProps.data.category, slotProps.data.type)" class="bg-primary text-white" />
                </template>
            </Column>
            <Column field="date" header="Datum">
                <template #body="slotProps">
                    <Chip :label="format_swiss_date(slotProps.data.date)" />
                </template>
            </Column>
            <Column field="status" header="Status">
                <template #body="{ data, field }">
                    <Chip v-if="data[field] == 'draft'" :label="get_status(data[field])" v-tooltip.top="'Beitrag ist Publiziert'" />
                    <Chip v-if="data[field] == 'published'" :label="get_status(data[field])" v-tooltip.top="'Beitrag ist Publiziert'" class="bg-green-200 font-bold" />
                </template>
            </Column>
            <Column>
                <template #body="slotProps">
                    <Chip icon="pi pi-box" class="mr-2 bg-primary text-white mr-2" :label="String(slotProps.data.blocks)" v-tooltip.top="'Anzahl Inhalt-Blocks'" />
                    <Chip icon="pi pi-exclamation-circle" class="mr-2 bg-red-700 text-white mr-2" :label="String(3)" v-tooltip.top="'Anzahl Pflichtfelder'" />
                </template>
                <template #header="slotProps">
                    <Button icon="pi pi-plus" class="mr-2 p-button-rounded" @click="new_pub(slotProps)" v-tooltip.top="'Publikation hinzufügen'" />
                </template>
            </Column>
        </DataTable>
    </div>
    <!---------------------------------------------------------------->
    <!-------------------------- Dialogs ----------------------------->
    <!---------------------------------------------------------------->
    <Sidebar header="Publikation bearbeiten" v-model:visible="slider_details" position="right" style="width: 40%">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-2">
                <Button icon="pi pi-box" label="Inhalte editieren" :badge="String(pub[active_pub].content.length)" badgeSeverity="contrast" class="w-full" @click="content_builder(pub[active_pub].content)" v-tooltip.top="'Publikations-Inhalte editieren'" />
            </div>
            <div class="field col-4 mt-2">
                <Button icon="pi pi-save" label="Speichern" class="w-full bg-green-700 border-green-600 text-white" v-tooltip.top="'Änderungen speichern'" />
            </div>
            <div class="field col-4 mt-2">
                <Button icon="pi pi-refresh" label="Zurücksetzen" class="w-full bg-orange-700 border-orange-600 text-white" v-tooltip.top="'Änderungen zurücksetzen'" />
            </div>
            <div class="field col-4 mt-2">
                <Button icon="pi pi-trash" label="Löschen" class="w-full bg-red-700 border-red-600 text-white" v-tooltip.top="'Publikation löschen'" />
            </div>
        </div>
        <TabView class="col-12" ref="tabview4">
            <TabPanel>
                <template #header>
                    <i class="pi pi-list mr-2"></i>
                    <span class="mr-3">Meta-Daten</span>
                    <Badge value="0" class="bg-red-700 text-white" />
                </template>
                <div class="col-12 formgrid grid">
                    <div class="field col-12 mt-4">
                        <span class="p-float-label">
                            <Dropdown v-model="pub[active_pub].status" :options="statuses" optionLabel="label" optionValue="value" class="w-full" />
                            <label for="status">Status</label>
                        </span>
                    </div>
                    <div class="field col-12 mt-4">
                        <span class="p-float-label">
                            <InputText id="title" v-model="pub[active_pub].title" class="w-full" />
                            <label for="title">Titel</label>
                        </span>
                    </div>
                    <div class="field col-12 mt-4">
                        <span class="p-float-label">
                            <Textarea id="teaser" v-model="pub[active_pub].teaser" class="w-full" />
                            <label for="teaser">Teaser</label>
                        </span>
                    </div>
                    <div class="field col-12 mt-4">
                        <span class="p-float-label">
                            <InputText id="title" v-model="pub[active_pub].meta_title" class="w-full" />
                            <label for="title">Meta-Titel</label>
                        </span>
                    </div>
                    <div class="field col-12 mt-4">
                        <span class="p-float-label">
                            <Textarea id="teaser" v-model="pub[active_pub].meta_description" class="w-full" />
                            <label for="teaser">Meta-Description</label>
                        </span>
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
                <template #header>
                    <i class="pi pi-images mr-2"></i>
                    <span class="mr-3">Bilder</span>
                    <Badge value="0" class="bg-red-700 text-white" />
                </template>
            </TabPanel>
            <TabPanel>
                <template #header>
                    <i class="pi pi-shopping-cart mr-2"></i>
                    <span class="mr-3">Produkte</span>
                    <Badge value="0" class="bg-red-700 text-white" />
                </template>
            </TabPanel>
        </TabView>
    </Sidebar>
    <!---------------------------------------------------------------->
    <!-------------------------- BUILDER ----------------------------->
    <!---------------------------------------------------------------->
    <Sidebar header="Publikation editieren" v-model:visible="slider_builder" position="left" style="width: 60%">
        <div>
            <Card class="shadow-4 z-5 mt-2" style="position: sticky; top: 0">
                <template #content>
                    <div class="flex align-items-center justify-content-center">
                        <Button icon="pi pi-save" label="Speichern" class="w-auto bg-green-700 border-green-600 text-white mr-2" v-tooltip.top="'Änderungen speichern'" />
                        <Button icon="pi pi-refresh" label="Zurücksetzen" class="w-auto bg-orange-700 border-orange-600 text-white mr-2" v-tooltip.top="'Änderungen zurücksetzen'" />
                        <Button icon="pi pi-eye" label="Vorschau" class="w-auto mr-2" v-tooltip.top="'Vorschau anzeigen'" />
                        <Button icon="pi pi-plus" class="bg-primary text-white mr-2" v-tooltip.top="'Block hinzufügen'" />
                    </div>
                </template>
            </Card>
            <template v-for="(block, key) in pub_content" :key="key">
                <template v-if="block.template == 'standard_text'">
                    <Card class="shadow-4 mt-3">
                        <template #content>
                            <div class="flex align-items-center justify-content-end">
                                <i class="pi pi-save text-green-700 mr-3 cursor-pointer" v-tooltip.top="'Block speichern'"></i>
                                <i class="pi pi-trash text-red-700 mr-3 cursor-pointer" v-tooltip.top="'Block löschen'"></i>
                                <i class="pi pi-plus text-primary mr-4 cursor-pointer" v-tooltip.top="'Block hinzufügen (unten)'"></i>
                                <i class="pi pi-angle-up text-purple-700 mr-3 cursor-pointer" v-tooltip.top="'Block nach oben schieben'"></i>
                                <i class="pi pi-angle-down text-purple-700 mr-3 cursor-pointer" v-tooltip.top="'Block nach unten schieben'"></i>
                            </div>
                            <Fieldset :toggleable="true">
                                <template #legend>
                                    <div class="flex align-items-center">
                                        <Avatar icon="pi pi-box" shape="circle" class="mr-2 ml-2 bg-primary text-white" />
                                        <span class="font-bold mr-3">Standard Text</span>
                                    </div>
                                </template>
                                <div class="grid">
                                    <div class="field col-12">
                                        <Editor editorStyle="width: 100%;" v-model="block.html_content">
                                            <template v-slot:toolbar>
                                                <span class="ql-formats">
                                                    <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                                                    <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                                                    <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                                                    <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                                                    <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                                                </span>
                                            </template>
                                        </Editor>
                                    </div>
                                </div>
                            </Fieldset>
                        </template>
                    </Card>
                </template>
            </template>
        </div>
    </Sidebar>
</template>