<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from "primevue/useconfirm"
import { FilterMatchMode } from 'primevue/api'
import axios from 'axios'

/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()

// filters

// data

const product_search_value = ref("")
const products = ref([])
const selected_template = ref(null)

const templates = ref([])

const field_types = ref([
    {name: "Text", id: "text"},
    {name: "Checkbox", id: "checkbox"}
])

const get_templates = () => {
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gerstlv2/get-newsletter-templates')
    .then(response => {
        templates.value = response.data
        console.log("templates", templates.value)
    })
}

const create_template = () => {
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gerstlv2/create-newsletter-template', {name: new_template_name.value})
    .then(response => {
        get_templates()
        new_template_dialog.value = false
    })
}

const save_template = () => {
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gerstlv2/save-newsletter-template', {"id": selected_template.value.id, "name": selected_template.value.name, "html": JSON.parse(JSON.stringify(selected_template.value.html)), "fields": selected_template.value.fields, "prefix_html":  JSON.parse(JSON.stringify(selected_template.value.prefix_html)), "postfix_html": JSON.parse(JSON.stringify(selected_template.value.postfix_html))})
    .then(response => {
        get_templates()
        edit_template_dialog.value = false
    })
}

const edit_template = () => {
    if (selected_template.value.fields == null){
        selected_template.value.fields = []
    }
    edit_template_dialog.value = true
}

const edit_field = (e) => {
    console.log("edit_field", e)
    selected_template.value.fields[e.index][e.field] = e.newValue
}

const delete_field = (e) => {
    selected_template.value.fields.splice(e.index, 1)
}

const selected_products = ref([])
const new_template_name = ref("")

const add_product_slider = ref(false)
const new_template_dialog = ref(false)
const edit_template_dialog = ref(false)

const search_products = () => {
    if (product_search_value.value.length > 2) {
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gerstlv2/search-products', {search: product_search_value.value})
        .then(response => {
            console.log("search_results", response.data)
            products.value = response.data
        })
    }
}

onMounted(() => {
    get_templates()
})

const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}

const format_currency_without_prefix = (value) => {
    return Number(value).toLocaleString('de-CH', {minimumFractionDigits: 2, maximumFractionDigits: 2});
}
</script>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <Toast />
    <ConfirmDialog />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <Toolbar class="mt-2">
        <template #start>
            <Dropdown showClear v-model="selected_template" :options="templates" optionLabel="name" placeholder="Template auswählen" class="mr-2" />
            <Button :disabled="!selected_template ? true : false" icon="pi pi-pencil" class="mr-2 p-button-primary" @click="edit_template()" v-tooltip.top="'Template editieren'" />
            <Button icon="pi pi-plus" class="mr-2 p-button-primary" @click="new_template_dialog = true, new_template_name = ''" v-tooltip.top="'Neues Template'" />
        </template>
    </Toolbar>
    <Toolbar class="mt-2">
        <template #start>
            <Button icon="pi pi-plus" class="mr-2 p-button-rounded p-button-primary" @click="add_product_slider = true" v-tooltip.top="'Produkt hinzufügen'" />
            <span class="mr-2 font-bold">Produkte:</span>
            <Chip v-for="product in selected_products" :key="product" :label="product.sku" class="mr-2" removable />
            <span v-if="selected_products.length == 0" class="mr-2">Keine Produkte ausgewählt</span>
        </template>
    </Toolbar>
    <div v-if="selected_template" rounded class="overflow-hidden shadow-4 rounded border-200 mt-4 p-3">
        <div class="grid">
            <div v-for="field, index in selected_template.fields" :key="field" class="col-4">
                <span class="p-float-label">
                    <InputText :id="field.field_id" :v-model="selected_template.fields[index].field_id" class="w-full mt-4" />
                    <label :for="field.field_id">{{ field.field_name }}</label>
                </span>
            </div>
        </div>
    </div>
    <div rounded class="overflow-hidden shadow-4 rounded border-200 mt-4 p-3">
        <span v-if="!selected_template">Bitte ein Template auswählen</span>
        <span v-if="selected_template" v-html="selected_template.prefix_html + selected_template.html + selected_template.postfix_html"></span>
    </div>
    <!---------------------------------------------------------------->
    <!-------------------------- Dialogs ----------------------------->
    <!---------------------------------------------------------------->
    <Dialog v-model:visible="new_template_dialog" header="Neues Template" :modal="true" style="width: 50%">
        <div class="grid">
            <div class="col-12">
                <InputText v-model="new_template_name" placeholder="Name" class="w-full" />
            </div>
            <div class="col-12">
                <Button :disabled="!new_template_name ? true : false" label="Erstellen" icon="pi pi-check" class="p-button-success mr-2 w-auto" @click="create_template" />
                <Button label="Abbrechen" icon="pi pi-times" class="p-button-danger w-auto" @click="new_template_dialog = false" />
            </div>
        </div>
    </Dialog>
    <Dialog v-model:visible="edit_template_dialog" header="Template bearbeiten" :modal="true" style="width: 80%">
        <div class="grid">
            <div class="col-12">
                <InputText v-model="selected_template.name" placeholder="Name" class="w-full" />
            </div>
            <div class="col-12">
                <DataTable :value="selected_template.fields" responsiveLayout="scroll" editMode="cell" @cell-edit-complete="edit_field" class="w-full">
                    <Column field="field_id" header="Feld-ID">
                        <template #body="slotProps">
                            <span>{{ slotProps.data.field_id }}</span>
                        </template>
                        <template #editor="slotProps">
                            <InputText v-model="slotProps.data.field_id" class="w-full" />
                        </template>
                    </Column>
                    <Column field="field_name" header="Feld-Namen">
                        <template #body="slotProps">
                            <span>{{ slotProps.data.field_name }}</span>
                        </template>
                        <template #editor="slotProps">
                            <InputText v-model="slotProps.data.field_name" class="w-full" />
                        </template>
                    </Column>
                    <Column field="field_name" header="Feld-Typ">
                        <template #body="slotProps">
                            <span>{{ slotProps.data.field_type }}</span>
                        </template>
                        <template #editor="slotProps">
                            <Dropdown v-model="slotProps.data.field_type" :options="field_types" optionLabel="name" optionValue="id" class="w-full" />
                        </template>
                    </Column>
                    <Column field="field_default" header="Standardwert">
                        <template #body="slotProps">
                            <span>{{ slotProps.data.field_default }}</span>
                        </template>
                        <template #editor="slotProps">
                            <InputText v-model="slotProps.data.field_default" class="w-full" />
                        </template>
                    </Column>
                    <Column>
                        <template #body="slotProps">
                            <Button icon="pi pi-trash" @click="delete_field(slotProps)" class="p-button-rounded p-button-text p-button-danger" v-tooltip.top="'Produkt löschen'" />
                        </template>
                        <template #header>
                            <Button icon="pi pi-plus" @click="selected_template.fields.push({'field_id': '', 'field_name': '', 'field_type': 'text', 'field_default': 'Jetzt kaufen'})" class="mr-2 p-button-rounded" v-tooltip.top="'Feld hinzufügen'" />
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="col-12">
                <Label for="html">HTML</Label>
                <Textarea v-model="selected_template.html" id="html" style="min-height: 320px" class="w-full" />
            </div>
            <div class="col-12">
                <Textarea v-model="selected_template.prefix_html" class="w-full" />
            </div>
            <div class="col-12">
                <Textarea v-model="selected_template.postfix_html" class="w-full" />
            </div>
            <div class="col-12">
                <Button :disabled="!selected_template.name || !selected_template.html ? true : false" label="Speichern" icon="pi pi-check" class="p-button-success mr-2 w-auto" @click="save_template" />
                <Button label="Abbrechen" icon="pi pi-times" class="p-button-danger w-auto" @click="edit_template_dialog = false" />
            </div>
        </div>
    </Dialog>
    <!---------------------------------------------------------------->
    <!------------------------ Add Product --------------------------->
    <!---------------------------------------------------------------->
    <Sidebar header="Produkt suchen" v-model:visible="add_product_slider" position="right" style="width: 70%">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-4">
                <Toolbar class="mt-2">
                    <template #start>
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="product_search_value" @input="search_products" @change="search_products" placeholder="Suche" style="width: 400px" class="mr-2"  />
                        </span>
                    </template>
                </Toolbar>
                <div rounded class="overflow-hidden shadow-4 rounded border-200 mt-4">
                    <DataTable :value="products" :rows="100" responsiveLayout="scroll" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                        <Column field="sku" header="SKU">
                            <template #body="slotProps">
                                <span class="font-bold">{{ slotProps.data.sku }}</span>
                            </template>
                        </Column>
                        <Column field="title3" header="Name">
                            <template #body="slotProps">
                                <span>{{ slotProps.data.title3 }} - {{ slotProps.data.title2 }}</span>
                            </template>
                        </Column>
                        <Column field="title1" header="Produzent" />
                        <Column>
                            <template #body="slotProps">
                                <Chip v-if="slotProps.data.year" :label="slotProps.data.year.name" class="mr-2 mb-1" />
                                <Chip v-if="slotProps.data.region" :label="slotProps.data.region.name" class="mr-2 mb-1" />
                                <Chip v-if="slotProps.data.country" :label="slotProps.data.country.name" class="mr-2 mb-1" />
                            </template>
                        </Column>
                        <Column>
                            <template #body="slotProps">
                                <Button v-if="selected_products.indexOf(slotProps.data.sku) == -1" icon="pi pi-plus" class="mr-2 p-button-rounded p-button-primary" @click="selected_products.push(slotProps.data), console.log('selected_prodcuts', selected_products), toast.add({severity:'success', summary:'Erfolg', detail:'Produkt hinzugefügt', life: 3000})" v-tooltip.top="'Produkt hinzufügen'" />
                                <Button v-if="selected_products.indexOf(slotProps.data.sku) != -1" icon="pi pi-times" class="mr-2 p-button-rounded p-button-danger" @click="selected_products.splice(promo.value.products.findIndex(p => p.sku == slotProps.data.sku), 1), toast.add({severity:'success', summary:'Erfolg', detail:'Produkt entfernt', life: 3000})" v-tooltip.top="'Produkt aus Auswahl entfernen'" />
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </div>
    </Sidebar>
</template>