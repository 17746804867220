<script setup>
import { ref, onMounted, useSSRContext } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import { EnumStringBody } from '@babel/types'
/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
// filters
const filter_products = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
//////////////////
// Data Sources //
//////////////////
const products = ref([])
const details = ref()
const search_term = ref('')
const result_count = ref(100)
const product_details_slider = ref(false)
const sage_group_filter = ref([])
const sage_groups = ref([])
const producer_filter = ref(null)
const producers = ref([])
const show_fine_wines = ref(false)

const result_counts = [
    {label: '200', value: 100},
    {label: '200', value: 200},
    {label: '1000', value: 1000},
    {label: '2000', value: 2000},
    {label: '5000', value: 5000},
    {label: '10000', value: 10000},
]

onMounted( async () => {
    search_products()
    get_sage_groups()
})

const search_products = () => {
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gerstlv2/search-products', {search: search_term.value, limit: result_count.value, show_fine_wines: show_fine_wines.value})
    .then(response => {
        products.value = response.data
        loader.value = false
    })
}

const get_sage_groups = () => {
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gerstlv2/get-sage-groups')
    .then(response => {
        sage_groups.value = response.data.filters3.sageGroups
    })
}

const get_product_stats = () => {
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gerstlv2/product-stats', {sku: details.value.sku, to_date_day: details.value.to_date_day, to_date_month: details.value.to_date_month, from_date_day: details.value.from_date_day, from_date_month: details.value.from_date_month})
    .then(response => {
        details.value.stats = response.data
        details.value.days_stats = {labels: response.data.day_labels, datasets: [{data: response.data.day_sales, label: 'Verkaufte Einheiten', backgroundColor: 'rgba(55, 81, 255, 0.5)', borderColor: 'rgb(55, 81, 255)'}]}
        details.value.months_stats = {labels: response.data.month_labels, datasets: [{data: response.data.month_sales, label: 'Verkaufte Einheiten', backgroundColor: 'rgba(55, 81, 255, 0.5)', borderColor: 'rgb(55, 81, 255)'}]}
        details.value.to_date_day = response.data.to_date_day
        details.value.to_date_month = response.data.to_date_month
        details.value.from_date_day = response.data.from_date_day
        details.value.from_date_month = response.data.from_date_month
        details.value.total_sales_days = response.data.total_sales_days
        details.value.total_sales_months = response.data.total_sales_months
        details.value.total_days = response.data.total_days
        details.value.total_months = response.data.total_months
    })
}

const chartOptions = ref()
const product_details = (e) => {
    console.log(e.data)
    loader.value = true
    details.value = e.data
    details.value.to_date_day = null
    details.value.to_date_month = null
    details.value.from_date_day = null
    details.value.from_date_month = null
    details.value.total_sales_days = 0
    details.value.total_sales_months = 0
    details.value.total_days = 0
    details.value.total_months = 0
    get_product_stats()
    chartOptions.value = setChartOptions()
    product_details_slider.value = true
    loader.value = false
}

const setChartOptions = () => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    return {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
            legend: {
                labels: {
                    color: textColor
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                }
            },
            y: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                }
            }
        }
    }
}

const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}

const format_currency_without_prefix = (value) => {
    return Number(value).toLocaleString('de-CH', {minimumFractionDigits: 2});
}

const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

const format_swiss_date_time = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4) + ' ' + value.substring(11,16)
}

const open_url = (url) => {
    window.open(url,'_blank')
}

const copy_to_clipboard = (value) => {
    navigator.clipboard.writeText(value)
    toast.add({severity:'success', summary: 'Erfolgreich', detail: 'In Zwischenablage kopiert', life: 3000});
}

</script>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>


<template>
    <Toast />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <BlockUI :fullScreen="true" :blocked="loader">
        <Toolbar>
            <template #start>
                <div class="flex align-items-start align-items-center">
                    <span class="p-input-icon-left mr-2" style="width: 300px">
                        <i class="pi pi-search" />
                        <InputText v-model="search_term" v-on:keyup.enter="search_products()" placeholder="Suche" />
                    </span>
                    <Button label="Suchen" icon="pi pi-search" class="mr-2" @click="search_products()" style="width: 170px" />
                    <Dropdown @change="search_products()" v-model="result_count" :options="result_counts" optionLabel="label" optionValue="value" placeholder="Resultate" style="width: 150px" class="mr-2" />
                    <MultiSelect v-model="sage_group_filter" :options="sage_groups" optionLabel="data.name" optionValue="data.key" placeholder="Sage Gruppen" style="width: 250px" class="mr-2" />
                    <MultiSelect v-model="producer_filter" :options="producers" optionLabel="data.name" optionValue="data.key" placeholder="Produzenten" style="width: 250px" class="mr-2" />
                    <div style="width: 400px" class="flex align-items-center">
                        <Checkbox v-model="show_fine_wines" :binary="true" class="mr-2" @change="search_products()" /><span class="white-space">Fine Wines einblenden</span>
                    </div>
                </div>
            </template>
        </Toolbar>
        <div rounded class="overflow-hidden shadow-3 border-200 mt-4">
            <DataTable :value="products" @row-click="product_details" :rows="200" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[200,500,1000]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                <Column>
                    <template #body="slotProps">
                        <div class="w-3rem h-3rem shadow-2 overflow-hidden">
                            <Image :src="slotProps.data.image_small" />
                        </div>
                    </template>
                </Column>
                <Column field="sku" header="SKU">
                    <template #body="slotProps">
                        <span class="font-bold">{{ slotProps.data.sku }}</span>
                    </template>
                </Column>
                <Column field="title3" header="Name">
                    <template #body="slotProps">
                        <span v-if="slotProps.data.title3">{{ slotProps.data.title3 }}</span><span v-if="slotProps.data.title2 && slotProps.data.title3"> - </span><span v-if="slotProps.data.title2">{{ slotProps.data.title2 }}</span>
                    </template>
                </Column>
                <Column field="title1" header="Produzent" />
                <Column field="year" header="Jahr">
                    <template #body="slotProps">
                        <span v-if="slotProps.data.year">{{ slotProps.data.year.name }}</span>
                    </template>
                </Column>
                <Column field="country" header="Land">
                    <template #body="slotProps">
                        <span v-if="slotProps.data.country">{{ slotProps.data.country.name }}</span>
                    </template>
                </Column>
                <Column field="region" header="Region">
                    <template #body="slotProps">
                        <span v-if="slotProps.data.region">{{ slotProps.data.region.name }}</span>
                    </template>
                </Column>
            </DataTable>
        </div>
    </BlockUI>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->
    <Sidebar v-model:visible="product_details_slider" position="right" style="width: 60rem">
        <TabView class="col-12">
            <TabPanel>
                <template #header>
                    <i class="pi pi-align-justify mr-2"></i>
                    <span class="mr-3">Daten</span>
                </template>
                <div class="col-12 formgrid grid">
                    <div class="field col-12 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                        <Image preview class="w-12rem" :src="details.image_large" />
                    </div>
                    <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                        Artikel-Nr.
                    </div>
                    <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <span class="font-bold">{{details.sku}}</span>
                        <i @click="copy_to_clipboard(details.sku)" v-tooltip.top="'In die Zwischenablage kopieren'" class="pi pi-copy ml-4 text-primary cursor-pointer" />
                        <i @click="open_url('https://gerstl.ch/' + details.slug + '/p')" v-tooltip.top="'Auf der Webseite ansehen'" class="pi pi-link ml-4 text-primary cursor-pointer" />
                        <i @click="open_url('https://cdn.gerstl.ch/product-pdf-v2/' + details.slug + '-mit-preis.pdf')" v-tooltip.top="'PDF mit Preis'" class="pi pi-file-pdf ml-4 text-primary cursor-pointer" />
                        <i @click="open_url('https://cdn.gerstl.ch/product-pdf-v2/' + details.slug + '-ohne-preis.pdf')" v-tooltip.top="'PDF ohne Preis'" class="pi pi-file-pdf ml-4 text-primary cursor-pointer" />
                    </div>
                    <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                        Jahr
                    </div>
                    <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <span v-if="details.year">{{details.year["name"]}}</span>
                    </div>
                    <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                        Verpackung
                    </div>
                    <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <span>{{details.packaging["long"]}}</span>
                    </div>
                    <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                        Titel 1
                    </div>
                    <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <span>{{details.title1}}</span>
                    </div>
                    <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                        Titel 2
                    </div>
                    <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <span>{{details.title2}}</span>
                    </div>
                    <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                        Titel 3
                    </div>
                    <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <span>{{details.title3}}</span>
                    </div>
                    <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                        Typ
                    </div>
                    <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <span v-if="details.wineType">{{details.wineType.name}}</span>
                    </div>
                    <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                        Klassifizierung
                    </div>
                    <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <span>{{details.classification}}</span>
                    </div>
                    <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                        Appellation
                    </div>
                    <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <span v-if="details.appellation">{{details.appellation.name}}</span>
                    </div>
                    <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                        Genussreife
                    </div>
                    <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <span>{{details.bestBefore}}</span>
                    </div>
                    <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                        Traubensorte/n
                    </div>
                    <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <div v-if="details.grapeType">
                            <Chip v-for="grape in details.grapeType" :key="grape.key" :label="grape.name" class="mr-2 bg-primary text-white mb-1" />
                        </div>
                    </div>
                    <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                        Traubenanteil
                    </div>
                    <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <div v-if="details.grapes">
                            <Chip v-for="grape in details.grapes" :key="grape" :label="grape" class="mr-2 bg-primary text-white mb-1" />
                        </div>
                    </div>
                    <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                        Land
                    </div>
                    <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <span>{{details.country["name"]}}</span>
                    </div>
                    <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                        Region
                    </div>
                    <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <span v-if="details.region">{{details.region.name}}</span>
                    </div>
                    <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                        Subregion
                    </div>
                    <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <span v-if="details.subregion">{{details.subregion.name}}</span>
                    </div>
                    <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                        Preise
                    </div>
                    <div class="field col-9 border-blue-100 border-solid border-bottom-1 m-0">
                        <div class="p-2 border-blue-100 border-solid border-bottom-1 m-0">
                            <span class="font-bold">PR:</span> {{format_currency(details.pricesV2.pr.price)}}
                        </div>
                        <div class="p-2 border-blue-100 border-solid border-bottom-1 m-0">
                            <span class="font-bold">HR:</span> {{format_currency(details.pricesV2.hr.price)}}
                        </div>
                        <div class="p-2 border-blue-100 border-solid border-bottom-1 m-0">
                            <span class="font-bold">HR2:</span> {{format_currency(details.pricesV2.hR2.price)}}
                        </div>
                        <div class="p-2 border-blue-100 border-solid border-bottom-1 m-0">
                            <span class="font-bold">WS:</span> {{format_currency(details.pricesV2.ws.price)}}
                        </div>
                    </div>
                    <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                        Weinalarm
                    </div>
                    <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <span v-if="details.stock.wineAlarm">Ja</span>
                        <span v-if="!details.stock.wineAlarm">Nein</span>
                    </div>
                    <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                        Lager
                    </div>
                    <div class="field col-9 border-blue-100 border-solid border-bottom-1 m-0">
                        <div class="p-2 border-blue-100 border-solid border-bottom-1 m-0">
                            <span class="font-bold mr-2">Nachricht:</span> {{details.stock.delivery}}
                        </div>
                        <div class="p-2 border-blue-100 border-solid border-bottom-1 m-0">
                            <span class="font-bold mr-2">Auf Lager:</span> <span v-if="details.stock.stock > 0" class="text-green-600">{{details.stock.stock}}</span> <span v-if="details.stock.stock == 0" class="text-red-500">{{details.stock.stock}}</span>
                        </div>
                        <div class="p-2 border-blue-100 border-solid border-bottom-1 m-0">
                            <span class="font-bold mr-2">Zentrallager:</span> <span v-if="details.stock.stockAtCentral > 0" class="text-green-600">{{details.stock.stockAtCentral}}</span> <span v-if="details.stock.stockAtCentral == 0" class="text-red-500">{{details.stock.stockAtCentral}}</span>
                        </div>
                        <div class="p-2 border-blue-100 border-solid border-bottom-1 m-0">
                            <span class="font-bold mr-2">Weinshop Spreitenbach:</span> <span v-if="details.stock.stockAtWeinShop > 0" class="text-green-600">{{details.stock.stockAtWeinShop}}</span> <span v-if="details.stock.stockAtWeinShop == 0" class="text-red-500">{{details.stock.stockAtWeinShop}}</span>
                        </div>
                        <div class="p-2 border-blue-100 border-solid border-bottom-1 m-0">
                            <span class="font-bold mr-2">Weinshop Sempach:</span> <span v-if="details.stock.stockAtSempach > 0" class="text-green-600">{{details.stock.stockAtSempach}}</span> <span v-if="details.stock.stockAtSempach == 0" class="text-red-500">{{details.stock.stockAtSempach}}</span>
                        </div>
                        <div class="p-2 border-blue-100 border-solid border-bottom-1 m-0">
                            <span class="font-bold mr-2">Primeur:</span> <span v-if="details.stock.stockOther > 0" class="text-green-600">{{details.stock.stockOther}}</span> <span v-if="details.stock.stockOther == 0" class="text-red-500">{{details.stock.stockOther}}</span>
                        </div>
                    </div>
                    <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                        Alcohol
                    </div>
                    <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <span v-if="details.alcohol">{{details.alcohol}}%</span>
                    </div>
                    <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                        Sage Gruppen
                    </div>
                    <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <Chip v-for="group in details.sageGroups" :key="group.key" :label="group.name" class="mr-2 bg-primary text-white mb-1" />
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
                <template #header>
                    <i class="pi pi-chart-bar mr-2"></i>
                    <span class="mr-3">Statistiken</span>
                    <Badge :value="details.sales" class="bg-primary mr-3" />
                </template>
                <div class="col-12 formgrid grid">
                    <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                        Artikel-Nr.
                    </div>
                    <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <span class="font-bold">{{details.sku}}</span>
                        <i @click="copy_to_clipboard(details.sku)" v-tooltip.top="'In die Zwischenablage kopieren'" class="pi pi-copy ml-4 text-primary cursor-pointer" />
                        <i @click="open_url('https://gerstl.ch/' + details.slug + '/p')" v-tooltip.top="'Auf der Webseite ansehen'" class="pi pi-link ml-4 text-primary cursor-pointer" />
                        <i @click="open_url('https://cdn.gerstl.ch/product-pdf-v2/' + details.slug + '-mit-preis.pdf')" v-tooltip.top="'PDF mit Preis'" class="pi pi-file-pdf ml-4 text-primary cursor-pointer" />
                        <i @click="open_url('https://cdn.gerstl.ch/product-pdf-v2/' + details.slug + '-ohne-preis.pdf')" v-tooltip.top="'PDF ohne Preis'" class="pi pi-file-pdf ml-4 text-primary cursor-pointer" />
                    </div>
                    <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                        Total Verkäufe
                    </div>
                    <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <span class="font-bold">{{details.sales}}</span>
                        <i @click="open_url('https://api.snpy.ch/gerstlv2/export-product?id=' + details.sku)" v-tooltip.top="'Export Verkäufe'" class="pi pi-file-excel ml-4 text-primary cursor-pointer" />
                    </div>
                    <div class="field col-12 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                        Täglich: <span class="text-red-500">{{ details.total_sales_days }}</span> Verkäufe in den letzten <span class="text-red-500">{{ details.total_days }}</span> Tagen
                    </div>
                    <div class="field col-2 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 align-content-center">
                        Von
                    </div>
                    <div class="field col-4 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <Calendar v-model="details.from_date_day" showIcon dateFormat="yy-mm-dd" class="w-full" @date-select="get_product_stats" />
                    </div>
                    <div class="field col-2 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 align-content-center">
                        Bis
                    </div>
                    <div class="field col-4 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <Calendar v-model="details.to_date_day" showIcon dateFormat="yy-mm-dd" class="w-full" @date-select="get_product_stats" />
                    </div>
                    <div class="field col-12 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 align-content-center">
                        <Chart type="bar" :data="details.days_stats" :options="chartOptions" class="h-30rem" />
                    </div>
                    <div class="field col-12 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                        Monatlich: <span class="text-red-500">{{ details.total_sales_months }}</span> Verkäufe in den letzten <span class="text-red-500">{{ details.total_months }}</span> Monaten
                    </div>
                    <div class="field col-2 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 align-content-center">
                        Von
                    </div>
                    <div class="field col-4 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <Calendar v-model="details.from_date_month" showIcon dateFormat="yy-mm-dd" class="w-full" @date-select="get_product_stats" />
                    </div>
                    <div class="field col-2 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 align-content-center">
                        Bis
                    </div>
                    <div class="field col-4 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <Calendar v-model="details.to_date_month" showIcon dateFormat="yy-mm-dd" class="w-full" />
                    </div>
                    <div class="field col-12 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 align-content-center">
                        <Chart type="bar" :data="details.months_stats" :options="chartOptions" class="h-30rem" @date-select="get_product_stats" />
                    </div>
                </div>
            </TabPanel>
        </TabView>
        <template #header>
            <div class="flex">
                <button class="p-sidebar-icon p-link mr-2">
                    <span class="pi pi-print" />
                </button>
            </div>
        </template>
    </Sidebar>
</template>